import { Controller as BaseController } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"
import Underline from "@tiptap/extension-underline"

export class Controller extends BaseController {
  static targets = ["input"]

  connect() {
    console.log("RichTextarea connected")
    const target = this.inputTarget

    this.editor = new Editor({
      element: this.element,
      extensions: [StarterKit, Underline],
      content: target.textContent,
      onUpdate() {
        const html = this.getHTML()
        console.log("Updating content:", html)
        target.innerHTML = html
      },
    })

    target.classList.add("hidden")
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run()
  }

  toggleItalics() {
    this.editor.chain().focus().toggleItalic().run()
  }

  toggleUnderline() {
    this.editor.chain().focus().toggleUnderline().run()
  }

  toggleHeadingOne() {
    this.editor.chain().focus().toggleHeading({ level: 1 }).run()
  }

  toggleHeadingTwo() {
    this.editor.chain().focus().toggleHeading({ level: 2 }).run()
  }

  toggleHeadingThree() {
    this.editor.chain().focus().toggleHeading({ level: 3 }).run()
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run()
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run()
  }

  toggleHorizontalRule() {
    this.editor.chain().focus().setHorizontalRule().run()
  }

  toggleBlockQuote() {
    this.editor.chain().focus().toggleBlockquote().run()
  }
}
