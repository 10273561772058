import { Controller } from "@hotwired/stimulus";
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Underline from "@tiptap/extension-underline"

export default class extends Controller {
  static targets = [ "notes" ]

  connect() {
    let formInput = this.notesTarget

    console.log("This element:", this.element)

    this.editor = new Editor({
      element: this.element,
      extensions: [
        StarterKit,
        Underline
      ],
      content: formInput.value,
      onUpdate() {
        formInput.innerHTML = this.getHTML();
      }
    })
  }

  toggleBold() {
    console.log("Activating... bold")
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalics() {
    console.log("Activating... italic")
    this.editor.chain().focus().toggleItalic().run();
  }

  toggleUnderline() {
    console.log("Activating... underline")
    this.editor.chain().focus().toggleUnderline().run();
  }

  toggleHeadingOne() {
    console.log("Activating... h1")
    this.editor.chain().focus().toggleHeading({ level: 1 }).run();

  }

  toggleHeadingTwo() {
    console.log("Activating... h2")
    this.editor.chain().focus().toggleHeading({ level: 2 }).run();
  }

  toggleHeadingThree() {
    console.log("Activating... h3")
    this.editor.chain().focus().toggleHeading({ level: 3 }).run();
  }

  toggleUnorderedList() {
    console.log("Activating... unorder list")
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    console.log("Activating... order list")
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleBlockQuote() {
    console.log("Activating... quote")
    this.editor.chain().focus().toggleBlockquote().run();
  }
}
